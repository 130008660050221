import React, { useState, useEffect } from "react";
import css from "./SideHeader.module.css";

import { sideHeaderElements } from "../../data/sideHeader";
const SideHeader = () => {
  const [active, setActive] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const navbar = document.getElementById("navbar");
      const sections = document.querySelectorAll("section");
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        if (
          currentScrollY >= sectionTop - navbar.offsetHeight &&
          currentScrollY < sectionTop + sectionHeight
        ) {
          setActive(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={css.vertical_header}>
      <div className={css.logo}>
        <img
          className="img-fluid d-block"
          src={sideHeaderElements[0].userAvatar}
          title={`I'm ${sideHeaderElements[0].name}`}
          alt="My profile"
        />

        <h1>{sideHeaderElements[0].name}</h1>
      </div>
      <nav id="navbar" className={css.menu}>
        <ul>
          {sideHeaderElements[1].list.map((element, i) => {
            return (
              <li key={i}>
                <a
                  className={active === element.link ? "active" : ""}
                  href={"#" + element.link}
                >
                  {element.text}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>

      <div className={css.sidebar_footer}>
        {sideHeaderElements[2].socialLinks.map((element, i) => {
          return (
            <a key={i} href={element.link} className={css.social_icon}>
              {element.icon}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SideHeader;
