import img1 from "../assets/myworks/Amazshop.png";
import img2 from "../assets/myworks/isim1.png";
import img3 from "../assets/myworks/jobzer1.png";
import img4 from "../assets/myworks/Modern Bank Website.png";
import img5 from "../assets/myworks/Nike - nikebyzer.png";
import img6 from "../assets/myworks/siya9ati.webp";
import img7 from "../assets/myworks/ISIM.png";
import img8 from "../assets/myworks/jobzer.png";
import img9 from "../assets/myworks/siya9ati1.png";

export const categories = ["All", "Web App", "Mobile App"];

export const myWorks = [
  {
    name: "Internship project",
    info: "Official website of OFPPT Industrial of Marrakesh",
    imgfull: img7,
    img: img2,
    category: "Web App",
    details: {
      client: "Administration of the Industrial Institute of Marrakech",
      tech: "HTML , CSS , BOOTSTRAP , JAVASCRIPT",
      date: "2024",
      link: "https://isim.netlify.app/",
    },
  },
  {
    name: "Siya9ati",
    info: "A mobile application for studying the traffic rules",

    img: img6 ,
    imgfull:img9 ,
    category: "Mobile App",
    details: {
      client: "Driving school",
      tech: "React Native",
      date: "2024",
      link: "https://play.google.com/store/apps/details?id=com.codnizer.siya9ati",
    },
  },
  {
    name: "Platform for job seekers",
    info: "Website for applying to and posting job offers",
    imgfull: img8,
    img: img3,
    category: "Web App",
    details: {
      client: "Application project",
      tech: "Tailwind CSS , Next js",
      date: "2024",
      link: "https://jobzer.vercel.app/",
    },
  },
  {
    name: "Modern Bank",
    info: "Modern Bank Website",
    imgfull:img4 ,
    img: img4,
    category: "Web App",
    details: {
      client: "Application project",
      tech: "React",
      date: "2023",
      link: "https://modernbk-website.netlify.app/",
    },
  },
  {
    name: "Amazshop",
    info: "E-commerce site for cosmetic products",
    imgfull:img1 ,
    img: img1,
    category: "Web App",
    details: {
      client: "Application project",
      tech: "React",
      date: "2023",
      link: "https://ecom-amazshop.netlify.app/",
    },
  },
  {
    name: "Store Nike",
    info: "Nike landing page",
    imgfull:img5 ,
    img: img5,
    category: "Web App",
    details: {
      client: "Application project",
      tech: "React",
      date: "2023",
      link: "https://nikebyzer.netlify.app/",
    },
  },

];
