import React from "react";
import { SiAboutdotme } from "react-icons/si";

import { AiOutlineDownload } from "react-icons/ai";
import Heading from "../../reusable/Heading";
import Detail from "./Detail.jsx";
import SkillBar from "react-skillbars";
import {
  myEducations,
  myExperiences,
  leftSkills,
  rightSkills,
} from "../../../data/resume";
import { about } from "../../../data/about";
const Resume = () => {
  return (
    <div
      style={{ paddingBottom: "7%", backgroundColor: "#fff" }}
      className="container px-lg-5"
    >
      <Heading icon={<SiAboutdotme />} headingTitle={"Resume"} />

      <div className="row gx-5">
        <div className="col-md-6">
          <h2 className="text-6 fw-600 mb-4 ">My Education</h2>

          {myEducations.map((myeducation, i) => {
            return (
              <Detail
                key={i}
                firstDate={myeducation.firstDate}
                secondDate={myeducation.secondDate}
                diplomaORexperience={myeducation.diplomaORexperience}
                location={myeducation.location}
                decription={myeducation.decription}
              />
            );
          })}
        </div>

        <div className="col-md-6">
          <h2 className="text-6 fw-600 mb-4 ">My Experience</h2>
          {myExperiences.map((myExperience, i) => {
            return (
              <Detail
                key={i}
                firstDate={myExperience.firstDate}
                secondDate={myExperience.secondDate}
                diplomaORexperience={myExperience.diplomaORexperience}
                location={myExperience.location}
                decription={myExperience.decription}
              />
            );
          })}
        </div>
      </div>
      <h2 className="text-6 fw-600 mt-4 mb-4 ">My Skills</h2>
      <div className="row gx-5">
        <div className="col-md-6">
          <SkillBar skills={leftSkills} height={"5vh"} />
        </div>
        <div className="col-md-6">
          <SkillBar skills={rightSkills} height={"5vh"} />
        </div>
      </div>
      <center>
        {/* <a
          href={about.cvDownloadLink}
          type="button"
          className="btn btn-outline-secondary"
        >
          Download My CV
          <AiOutlineDownload fontSize={"20px"} />{" "}
        </a> */}
      </center>
    </div>
  );
};

export default Resume;
