import React from "react";
import css from "./about.module.css";
import { BiUserCircle } from "react-icons/bi";
import Heading from "../../reusable/Heading";
import { about } from "../../../data/about";
import { sideHeaderElements } from "../../../data/sideHeader";
const About = () => {
  return (
    <div style={{ marginBottom: "7%" }} className="container px-lg-5">
      <Heading icon={<BiUserCircle />} headingTitle={"Know Me More"} />

      <div className={css.description + " " + "row gy-5"}>
        <div className="col-lg-7 col-xl-8 text-center text-lg-start">
          {/* img for mobile */}
          <div className={css.imgMobile}>
            <img
              className="img-fluid d-block"
              src={sideHeaderElements[0].userAvatar}
              title={`I'm ${sideHeaderElements[0].name}`}
              alt="My profile"
            />
          </div>
          {/* end */}
          <h2 className="text-7 fw-600 mb-3 ">
            I'm <span className="text-primary">{about.personalName},</span> a{" "}
            <span> </span>
            {about.job}
          </h2>
          <p>{about.descriptionFirstPart}</p>
          <p>{about.descriptionSecondPart}</p>
        </div>

        <div className={css.infowrap + " " + "col-lg-5 col-xl-4"}>
          <div className={css.info + " " + "ps-lg-4"}>
            <ul className="list-style-2 ">
              <li>
                <span className={css.firstchild}>Name: </span>
                {about.personalName}
              </li>
              <li>
                <span className={css.firstchild}>Email: </span>
                <a href={`mailto:${about.email}`}>{about.email}</a>
              </li>
              <li>
                <span className={css.firstchild}>Age: </span>
                {about.age}
              </li>
              <li className="border-0">
                <span className={css.firstchild}>From: </span>
                {about.from}
              </li>
            </ul>
            {/* <a
              href={about.cvDownloadLink}
              download=""
              className="btn btn-primary rounded-pill"
            >
              Download CV
            </a> */}
          <a
  href="https://wa.me/+212766141884?text=Hello,%20I%20would%20like%20to%20know%20more%20about%20your%20services."
  className="btn btn-success rounded-pill"
  target="_blank"
  rel="noopener noreferrer"
>
  Contact me Now
</a>
          </div>
        </div>
      </div>

      <div className="brands-grid separator-border mt-5 ">
        <div className={css.row + " " + "row"}>
          <div className="col-6 col-md-3">
            <div className="featured-box text-center">
              <h4 className="text-12  mb-0  ">
                <span>{about.yearsOfExperience}</span>+
              </h4>
              <p className="mb-0 ">Years Experience</p>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="featured-box text-center">
              <h4 className="text-12  mb-0  ">
                <span>{about.happyClients}</span>+
              </h4>
              <p className="mb-0 ">Happy Clients</p>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="featured-box text-center">
              <h4 className="text-12  mb-0  ">
                <span>{about.projectsDone}</span>+
              </h4>
              <p className="mb-0 ">Projects Done</p>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <div className="featured-box text-center">
              <h4 className="text-12  mb-0  ">
                <span>{about.getAwards}</span>
              </h4>
              <p className="mb-0 ">Get Awards</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
