import { TypeAnimation } from "react-type-animation";
import { home } from "../../../data/home";
import "./typing.css";
const TypingEffect = () => {
  return (
    <TypeAnimation
      sequence={[
        home.firstText, // Types 'One'
        5000, // Waits 5s
        home.secondText, // Deletes 'One' and types 'Two'
        5000, // Waits 5s

        () => {},
      ]}
      wrapper="span"
      cursor={true}
      className="typing"
      repeat={Infinity}
      style={{
        fontSize: "5em",
        display: "inline-block",
        fontFamily: "Poppins",
      }}
    />
  );
};
export default TypingEffect;
