import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
 import { footer } from "../../../data/footer";
const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  return (
    <>
    <center style={{ marginBottom: "10px" }}>
        <p>Copyright © <span>{footer.year}</span> <span>{footer.copyrightName}</span>. All Rights Reserved.</p>
</center>
      {/* <center style={{ marginBottom: "10px" }}>
        <p>Copyright © <span>{footer.year}</span> <span>{footer.copyrightName}</span>. All Rights Reserved.</p>

        <div style={{ display: "inline-block" }}>
          <a
            style={{cursor:"pointer", color: "#0d6efd", display: "inline-block" }}
            onClick={handleShow}
            className="nav-link"
 
          >
            Terms &amp; Policy
          </a>

          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Terms & Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body>

{/* Terms and policy body */}
{/* <p>Simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
<h4><strong>Terms of Use</strong></h4>
<p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Simply dummy text of the printing and typesetting industry.</p>
<h4><strong>Part I &ndash; Information Simone collects and controls</strong></h4>
<p>Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<h4><strong>Part II &ndash; Information that Simone processes on your behalf</strong></h4>
<p>Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<h4><strong>Part III &ndash; General</strong></h4>
<p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<h4><strong>Privacy Policy</strong></h4>
<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry&#39;s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
<ol>
	<li>Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.</li>
	<li>Quidam lisque persius interesset his et, Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.</li>
	<li>In quot quidam persequeris vim, ad mea essent possim iriure. Quidam lisque persius interesset his et.</li>
	<li>Quidam lisque persius interesset his et, Lisque persius interesset his et.</li>
	<li>Interesset his et, Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.</li>
	<li>Persius interesset his et, Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.</li>
	<li>Quot quidam persequeris vim Quidam lisque persius interesset his et, Lisque persius interesset his et, in quot quidam persequeris vim, ad mea essent possim iriure.</li>
</ol> */}

              {/* End of Terms and policy body */}
            {/* </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal> */}

          {/* <p style={{ display: "inline-block", margin: "0px 10px" }}>|</p>

          <a
            style={{cursor:"pointer", color: "#0d6efd", display: "inline-block" }}
            className="nav-link"
            onClick={handleShow1}
          >
            Disclaimer
          </a>

          <Modal size="lg" show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
              <Modal.Title>Copyright & Disclaimer</Modal.Title>
            </Modal.Header>
            <Modal.Body> */}

              {/* Copyright Body */}
              {/* <p>
                Simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.
              </p>
              <ul className="lh-lg">
                <li>
                  Lisque persius interesset his et, in quot quidam persequeris
                  vim, ad mea essent possim iriure.
                </li>
                <li>
                  Quidam lisque persius interesset his et, Lisque persius
                  interesset his et, in quot quidam persequeris vim, ad mea
                  essent possim iriure.
                </li>
                <li>
                  In quot quidam persequeris vim, ad mea essent possim iriure.
                  Quidam lisque persius interesset his et.
                </li>
                <li>
                  Quot quidam persequeris vim Quidam lisque persius interesset
                  his et, Lisque persius interesset his et, in quot quidam
                  persequeris vim, ad mea essent possim iriure.
                </li>
              </ul> */}
               {/* End of Copyright Body */}
            {/* </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose1}>
                Close
              </Button>
            </Modal.Footer>
          </Modal> */}
        {/* </div> */}
      {/* </center> */}  
    </>
  );
};

export default Footer;
