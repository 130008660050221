import React from "react";
import css from "./services.module.css";

const FeaturedBox = ({ icon, title, text }) => {
  return (
    <div className="col-md-6">
      <div className={css.featuredbox}>
        <div className={css.iconbox}>{icon}</div>
        <div className={css.infobox}>
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default FeaturedBox;
