import React from "react";
import css from "./portfolio.module.css";

import { SiCountingworkspro } from "react-icons/si";


import Heading from "../../reusable/Heading";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import Work from "./Work";
import { categories, myWorks } from "../../../data/portfolio";
const Portfolio = () => {
  const [parent, enableAnimations] = useAutoAnimate(/* optional config */);
  const [menumyWorks, setmenumyWorks] = React.useState(myWorks);
  const [selected, setselected] = React.useState(categories[0]);
  const filter = (type) => {
    setmenumyWorks(myWorks.filter((work) => work.category === type));
  };
  return (
    <div
      style={{ paddingBottom: "7%" }}
      className="container px-lg-5   bg-light"
    >
      <Heading icon={<SiCountingworkspro />} headingTitle={"My Works"} />

      <div className={css.works}>
        <ul className={css.menu} ref={parent}>
          {categories.map((category, i) => {
            return (
              <li
                key={i}
                style={
                  selected == category
                    ? {
                        color: "var(--primary-color)",
                        borderBottom: "1px solid",
                      }
                    : {}
                }
                onClick={() => {
                  category == categories[0]
                    ? setmenumyWorks(myWorks)
                    : filter(category);
                  setselected(category);
                }}
              >
                {category}
              </li>
            );
          })}
        </ul>
        <div className={css.list} ref={parent}>
          {menumyWorks.map((work, i) => (
            <div key={i} className={css.work}>
              <Work data={work} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
