import React from "react";
import { FiSend } from "react-icons/fi";
import Heading from "../../reusable/Heading";
import ContactForm from "./Form";
import { sideHeaderElements } from "../../../data/sideHeader";
import { MdOutlineMailOutline, MdPhone } from "react-icons/md";
import css from "./form.module.css";
import { contact } from "../../../data/contact";
const Contact = () => {
  return (
    <div
      className="container px-lg-5   bg-light"
      style={{ paddingBottom: "7%", marginBottom: "7%" }}
    >
      <Heading icon={<FiSend />} headingTitle={"Contact Me"} />

      <div className="row gy-5">
        <div className="col-md-8 col-xl-9 order-1 order-md-1">
          <h2 className="mb-3 text-5 text-uppercase text-center text-md-start ">
            Send us a note
          </h2>

          <ContactForm />
        </div>

        <div className="col-md-4 col-xl-3 order-0 order-md-0 text-center text-md-start">
          <h2 className="mb-3 text-5 text-uppercase ">Address</h2>
          <p className="text-3 mb-4 ">
            {contact.streetAdress}
            <br />
            {contact.stateFull}
            <br />
            {contact.city}
          </p>

          <p style={{ color: "#333", fontWeight: "400" }} className="text-3 mb-4 ">
            <span className="text-primary text-4 me-2">
              <MdOutlineMailOutline />
            </span>
            {contact.email}
          </p>

          <p style={{ color: "#333", fontWeight: "400" }} className="text-3 mb-4 ">
            <span className="text-primary text-4 me-2">
              <MdPhone />
            </span>
            {contact.phoneNumber}
          </p>
          <div
            className={css.socialicons}
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "20px",
              width: "80%",
            }}
          >
            {sideHeaderElements[2].socialLinks.map((element, i) => {
              return <a key={i} href={element.link}>{element.icon}</a>;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
