
import { FaLaptopCode } from "react-icons/fa";

import { FaAndroid } from "react-icons/fa";  


import { GiPencilBrush } from "react-icons/gi";
import { IoMdAnalytics } from "react-icons/io";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import { MdVideoSettings } from "react-icons/md";


const fontSz = "2.5rem";

export const myServices = [
    
      {
        title:"Web Development",
        text:"We create high-quality, custom websites tailored to your business needs, ensuring exceptional design and functionality.",
        icon:<FaLaptopCode fontSize={fontSz} />
       },
       {
        title:"App dev",
        text:"We create custom, high-quality apps that meet your business needs, offering outstanding design and performance.",
        icon:<GiPencilBrush fontSize={fontSz} />
       },
       {
        title:"Advertising",
        text:"We craft custom, high-quality ads that perfectly align with your business goals, delivering exceptional design and impact.",
        icon:<BsFillRocketTakeoffFill fontSize={fontSz} />
       },
       
       
]
