import React from "react";
import css from "./resume.module.css";
const Detail = ({
  firstDate,
  secondDate,
  diplomaORexperience,
  location,
  decription,
}) => {
  return (
    <div className={css.detail + " bg-white  rounded p-4 mb-4 bg-white border"}>
      <p className={css.badge + " badge bg-primary text-2 fw-400"}>
        {firstDate} - {secondDate}
      </p>
      <h3 className={css.title + " text-5"}>{diplomaORexperience}</h3>
      <p className={css.label + " text-danger"}>{location}</p>
      <p className="mb-0 ">{decription}</p>
    </div>
  );
};

export default Detail;
