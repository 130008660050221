import React from "react";
import css from "./home.module.css";
import TypingEffect from "./TypingEffect";
import cover from "../../../assets/cover.jpg";
import { AiFillCaretDown } from "react-icons/ai";

import { home } from "../../../data/home";
const Home = () => {
  return (
    <div
      className={css.home}
      style={{
        backgroundImage: `url(${cover})`,
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        // zIndex:999
      }}
    >
      <div className={css.cashbg}>
        <div className={css.infowrapper}>
          <div className={css.infos}>
            <h2>Welcome</h2>
            <TypingEffect />
            <p>{home.location}</p>
            <a
              href="#contact"
              className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
            >
              Hire Me
            </a>
          </div>
          <div className={css.scroll_down_arrow}>
            <AiFillCaretDown className={css.arrow_icon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
