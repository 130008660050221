import React from "react";
import Main from "../main/Main";
import SideHeader from "../sideheader/SideHeader";
import Navbar from "../navbar/Navbar";

const Layout = () => {
  return (
    <>
      <SideHeader />
      <Navbar />
      <Main />
    </>
  );
};

export default Layout;
