// import React, { useState } from "react";
// import css from "./form.module.css";
// import { contact } from "../../../data/contact";
// import { form } from "../../../data/contact";
// const ContactForm = () => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [message, setMessage] = useState("");

//   return (
//     <form
//       target="_blank"
//       action={`https://formsubmit.co/${contact.email}`}  
//       method="POST"
//     >
//       <div className={css.formgroup}>
//         <div className={css.formRow}>
//           <div className={css.col}>
//             <input
//               type="text"
//               name="name"
//               className={css.upperFormcontrol}
//               placeholder="Full Name"
//               required
//               value={name}
//               onChange={(e) => setName(e.target.value)}
//             />
//           </div>
//           <input type="hidden" name="_next" value={form.mySite}></input>
//           <input type="hidden" name="_captcha" value="false"></input>
//           <div className={css.col}>
//             <input
//               type="email"
//               name="email"
//               className={css.upperFormcontrol}
//               placeholder="Email Address"
//               required
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />
//           </div>
//         </div>
//       </div>
//       <div className="form-group">
//         <textarea
//           placeholder="Tell us about your needs..."
//           className={css.formControl}
//           name="message"
//           rows="10"
//           required
//           value={message}
//           onChange={(e) => setMessage(e.target.value)}
//         />
//       </div>
//       <center>
//         <button
//           type="submit"
//           className={"btn btn-lg btn-primary btn-block " + css.btnsubmit}
//           disabled={!name || !email || !message}
//         >
//           Send Message
//         </button>
//       </center>
//     </form>
//   );
// };

// export default ContactForm;


import React, { useState } from "react";
import emailjs from 'emailjs-com';
import css from "./form.module.css";
import { contact, form } from "../../../data/contact";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParams = {
      to_name: "Abdellah",  // Replace with the actual recipient name
      name: name,
      email: email,
      message: message
    };

    emailjs.send('service_hemhd7d', 'template_ris5ido', templateParams, '82QeZXt5wQtQCM8lY')
      .then((result) => {
        console.log(result.text);
        setLoading(false);
        setSuccess(true);
      }, (error) => {
        console.log(error.text);
        setLoading(false);
        setSuccess(false);
      });
  };

  return (
    <form onSubmit={sendEmail}>
      <div className={css.formgroup}>
        <div className={css.formRow}>
          <div className={css.col}>
            <input
              type="text"
              name="name"
              className={css.upperFormcontrol}
              placeholder="Full Name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={css.col}>
            <input
              type="email"
              name="email"
              className={css.upperFormcontrol}
              placeholder="Email Address"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <textarea
          placeholder="Tell us about your needs..."
          className={css.formControl}
          name="message"
          rows="10"
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <center>
        <button
          type="submit"
          className={"btn btn-lg btn-primary btn-block " + css.btnsubmit}
          disabled={!name || !email || !message || loading}
        >
          {loading ? "Sending..." : "Send Message"}
        </button>
      </center>
      {success === true && <p className="text-success" >Email sent successfully!</p>}
      {success === false && <p className="text-danger">Failed to send email. Please try again.</p>}
    </form>
  );
};

export default ContactForm;
