export const contact = {
  email: "khedidabdellah@gmail.com",
  phoneNumber:" (+212)7 66-14-18-84",
  streetAdress:"Massira 1,",
  stateFull:"",
  city:"Marrakech",
  };
  

  export const form ={
   mySite:"https://www.khedid.com/"
  }