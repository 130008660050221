import React from "react";
import css from "./heading.module.css";

const Heading = ({ icon, headingTitle }) => {
  return (
    <div className="position-relative d-flex text-center mb-5">
      <div className={css.header}>
        <h2>{icon}</h2>
        <p>
          {headingTitle}
          <span
            style={{ width: "30%", color: "var(--primary-color)" }}
            className="heading-separator-line w-30 border-bottom border-3 border-primary d-block mx-auto"
          ></span>
        </p>
      </div>
    </div>
  );
};

export default Heading;
