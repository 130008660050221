import React from "react";
import { MdDesignServices } from "react-icons/md";
import { myServices } from "../../../data/services";
import Heading from "../../reusable/Heading";
import FeaturedBox from "./FeaturedBox";
const Services = () => {
  const fontSz = "2.5rem";
  return (
    <div
      style={{ paddingBottom: "7%" }}
      className="container px-lg-5  bg-light"
    >
      <Heading icon={<MdDesignServices />} headingTitle={"What I Can Do?"} />

      <div className="row">
        <div className="col-lg-11 mx-auto">
          <div className="row">
            {myServices.map((service, i) => {
              return (
                <FeaturedBox
                  key={i}
                  title={service.title}
                  text={service.text}
                  icon={service.icon}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
