import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import { sideHeaderElements } from "../../data/sideHeader";
const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [active, setActive] = useState("");
  const toggleNavbar = () => {
    setIsMobile(!isMobile);
  };

  return (
    <nav className={`navbar ${isMobile ? "mobile" : ""}`}>
      <div className="navbar__container">
        <a href="/" className="navbar__logo">
          {sideHeaderElements[0].name}
        </a>
        <button className="navbar__toggle" onClick={toggleNavbar}>
          {isMobile ? <FaTimes /> : <FaBars />}
        </button>
        <ul className={`navbar__menu ${isMobile ? "mobile" : ""}`}>
          {sideHeaderElements[1].list.map((element, i) => {
            return (
              <li className="navbar__item" key={i}>
                <a
                  className={active === element.link ? "active" : ""}
                  href={"#" + element.link}
                >
                  {element.text}
                </a>
              </li>
            );
          })}
          <li style={{ display: "inline-block" }}></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
