import React, { useState, useEffect } from "react";
import About from "./about/About";
import Home from "./home/Home";
import css from "./main.module.css";
import Resume from "./resume/Resume";
import Services from "./services/Services";
import Portfolio from "./portfolio/Portfolio";
import Contact from "./contact/Contact";
import Footer from "./footer/Footer";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import { sideHeaderElements } from "../../data/sideHeader";

const Main = () => {
  const [currentSection, setCurrentSection] = useState(sideHeaderElements[1].list[0].link);

  useEffect(() => {
    const handleScroll = () => {
      const sections = sideHeaderElements[1].list.map(item => item.link);
      const scrollPosition = window.scrollY + 100; // Adjusted scroll position for accuracy

      // Find the current section based on scroll position
      for (let i = sections.length - 1; i >= 0; i--) {
        const section = document.getElementById(sections[i]);
        if (section.offsetTop <= scrollPosition) {
          setCurrentSection(sections[i]);
          break;
        }
      }
    };

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <div className={css.main}>
      <section id={sideHeaderElements[1].list[0].link}>
        <Home />
      </section>

      <section id={sideHeaderElements[1].list[1].link}>
        <About />
      </section>

      <section id={sideHeaderElements[1].list[2].link}>
        <Services />
      </section>

      <section id={sideHeaderElements[1].list[3].link}>
        <Resume />
      </section>

      <section id={sideHeaderElements[1].list[4].link}>
        <Portfolio />
      </section>

      <section id={sideHeaderElements[1].list[5].link}>
        <Contact />
      </section>

      <Footer />

      {/* WhatsApp Button - Conditionally render based on current section */}
      {currentSection !== sideHeaderElements[1].list[0].link && (
        <WhatsAppWidget open={true} className={css.whatsappButton} companyName="KHEDID Abdellah " phoneNumber="+212766141884" message="Hi How Can I Help you!">
          <button >
            <img
              src="path_to_your_whatsapp_icon_image"
              alt="WhatsApp Icon"
              className={css.greenIcon}
            />
          </button>
        </WhatsAppWidget>
      )}
    </div>
  );
};

export default Main;
