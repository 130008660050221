import avatar from "../assets/avatar.jpg";

import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
export const sideHeaderElements = [
  {
    userAvatar: avatar,
    name: "KHEDID ABDELLAH",
  },

  {
    list: [
      {
        link: "home",
        text: "Home",
      },
      {
        link: "about",
        text: "About Me",
      },
      {
        link: "services",
        text: "My Services",
      },
      {
        link: "resume",
        text: "Resume",
      },
      {
        link: "portfolio",
        text: "Portfolio",
      },
      // {
      //   link: "testimonial",
      //   text: "Testimonial",
      // },
      {
        link: "contact",
        text: "Contact",
      },
    ],
  },

  {
    socialLinks: [
        {
          link: "https://web.facebook.com/khedid.7",
          icon: <FaFacebookF />,
        },
      
      
      {
          link: "https://www.instagram.com/abduuuuu__llah_/",
          icon: <FaInstagram />,
        },
      {
          link: "https://www.linkedin.com/in/khedid-abdellah-6610a42a2?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
          icon: <FaLinkedinIn />,
        },
    ],
  },
];
