import React, { useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import css from "./portfolio.module.css";
import Stack from "react-bootstrap/Stack";

const Work = ({ data }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <div
      className={css.container_wrap + `  ${hovered ? " hovered" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src={data.img}
        alt="Original Image"
        className={hovered ? css.image_hover : css.image}
      />
      <div
        className={css.text}
        style={{
          display: hovered ? " block" : "none",
          fontWeight: "bold",
          fontFamily: "Poppins",
        }}
      >
        <h1 style={{ color: "#e55039" }}>{data.name}</h1>
        <p>{data.category}</p>
        <Button
          variant="primary"
          onClick={() => {
            handleShow();
            handleMouseLeave();
          }}
        >
          Know More
        </Button>
      </div>

      <Modal
        style={{ fontFamily: "Poppins" }}
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ margin: "0px auto" }}>{data.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stack direction="horizontal" gap={3}>
            <img
              style={{
                objectFit: "cover",
                width: "50%",
                height: "100%",
                minHeight: "80vh",
              }}
              src={data.imgfull}
            />
            <Stack gap={3}>
              <div>
                <h5
                  style={{ fontFamily: "Roboto Condensed", fontWeight: "bold" }}
                >
                  Project Info:
                </h5>
                
                <p>{data.info}</p>
              </div>

              <div>
                <h5
                  style={{ fontFamily: "Roboto Condensed", fontWeight: "bold" }}
                >
                 Client:
                </h5>
                 
                <p>{data.details.client}</p>
              </div>
              <div>
                <h5
                  style={{ fontFamily: "Roboto Condensed", fontWeight: "bold" }}
                >
                 Technologies:
                </h5>
                 
                <p>{data.details.tech}</p>
              </div>

              <div>
                <h5
                  style={{ fontFamily: "Roboto Condensed", fontWeight: "bold" }}
                >
                 Date:
                </h5>
                 
                <p>{data.details.date}</p>
              </div>

              <div>
                <h5
                  style={{ fontFamily: "Roboto Condensed", fontWeight: "bold" }}
                >
                 Link:
                </h5>
                 
                <a target="_blank"
                      style={{ textDecoration: "none" }}
                      href={ data.details.link}
                    >
                      Click Here
                    </a>
              </div>

              {/* <div>
                <h5
                  style={{ fontFamily: "Roboto Condensed", fontWeight: "bold" }}
                >
                  Details:
                </h5>
                <ListGroup variant="flush">
                  <ListGroup.Item>Client: <br/> {data.details.client}</ListGroup.Item>
                  <ListGroup.Item>
                    Technologies: {data.details.tech}
                  </ListGroup.Item>
                  <ListGroup.Item>Date: {data.details.date}</ListGroup.Item>
                  <ListGroup.Item>
                    Link:{" "}
                    <a
                      style={{ textDecoration: "none" }}
                      href={"http://" + data.details.link}
                    >
                      {data.details.link}
                    </a>
                  </ListGroup.Item>
                </ListGroup>
              </div> */}
            </Stack>
          </Stack>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Work;
